// export const API_URL = "http://15.184.202.217:3000";
// export const API_URL = "http://13.41.18.21:3000/api";

//export const API_URL = "http://18.221.140.83:3000/api/admin";
// export const API_URL = "https://happytaxiadmin.us/api/admin";
 export const API_URL = "https://dev.happytaxiadmin.us/api/admin";
export const ServiceType_Url = "https://happytaxiadmin.us/api/admin/serviceType";
export const permsiions = ["user"];
// export const Development_Url = "http://18.221.140.83:3000/api/admin";
export const ImageUploadUrl = "https://happytaxiadmin.us";
export const developmentDeleteUrl = "http://3.140.36.106:3000";
// export const LOCATION_API_KEY="AIzaSyCPA6L1R1Q7GF7uHV4NMrvZwEEKDVibDkE";

// export const LOCATION_API_KEY="AIzaSyAzWEYgzHRBWRwOJktxPWSJr4zTTZyxBdw";